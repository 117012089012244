// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import Rails from "@rails/ujs";
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()

//require("controllers")
require("controllers/index")


//require("jquery")
require("packs/widget")
require("packs/webflow")
require("packs/webflow_turbo")


//require("typeface-source-sans-pro");
//require("typeface-montserrat");
//require("typeface-bitter");

import '@fontsource-variable/source-sans-3';



document.addEventListener('turbolinks:load', () => {
 

  $(".submit-button").click(function() {
     console.log("Submit form");
	   $('form').submit();
	});

  $('input[type=radio]').change(function(){
      console.log("Input radio pressed",$(this).attr('name'));
      console.log($(this).parent().parent());
      $(this).parent().parent().find(".selection").removeClass("selected");
      var button = $(this).next();
      button.addClass("selected");
  }); 



  var accordion = (function(){
  
    var $accordion = $('.js-accordion');
    var $accordion_header = $accordion.find('.js-accordion-header');
    var $accordion_item = $('.js-accordion-item');

    console.log("Accordion", $accordion);
   
    // default settings 
    var settings = {
      // animation speed
      speed: 400,
      
      // close all other accordion items if true
      oneOpen: false,
    };
      
    return {
      // pass configurable object literal
      init: function($settings) {
        $accordion_header.on('click', function() {
          accordion.toggle($(this));
        });
        
        $.extend(settings, $settings); 
        
        // ensure only one accordion is active if oneOpen is true
        if(settings.oneOpen && $('.js-accordion-item.active').length > 1) {
          $('.js-accordion-item.active:not(:first)').removeClass('active');
        }
        
        // reveal the active accordion bodies
        $('.js-accordion-item.active').find('> .js-accordion-body').show();
      },
      toggle: function($this) {
              
        if(settings.oneOpen && $this[0] != $this.closest('.js-accordion').find('> .js-accordion-item.active > .js-accordion-header')[0]) {
          $this.closest('.js-accordion')
                 .find('> .js-accordion-item') 
                 .removeClass('active')
                 .find('.js-accordion-body')
                 .slideUp()
        }
        
        // show/hide the clicked accordion item
        $this.closest('.js-accordion-item').toggleClass('active');
        $this.next().stop().slideToggle(settings.speed);
      }
    }
  })();

  //Setup accordion
  accordion.init({ speed: 300, oneOpen: true });

  console.log("accordion",accordion);


  var headerElements = $(".header_element_wrapper");
  console.log("HeaderElement,",headerElements.first().is(":visible"));

  

  var currentVisibleIndex = 0;
  if(!headerElements.first().is(":visible"))
  {
    $(headerElements[currentVisibleIndex]).css("display", "flex").hide().fadeIn();
    setInterval(changeHeader, 5000);
  }
  function changeHeader()
  {
    $(headerElements[ currentVisibleIndex]).css("display", "flex").show().fadeOut(400,function(){
      currentVisibleIndex += 1;
      if(currentVisibleIndex==3)
      {
        currentVisibleIndex = 0;
      }
      $(headerElements[ currentVisibleIndex]).css("display", "flex").hide().fadeIn();
    }).css('display', 'flex');;
  }



   $(".wa_button").click(function() {

      console.log("Show popup");
      $(".wa_chat_wrapper").toggleClass("hidden");
    });
 
    $("#close-wa-popup").click(function(){
      $(".wa_chat_wrapper").addClass("hidden");
    });

    const countdownDate = new Date("Feb 08, 2025 00:00:00").getTime();

    const countdowns = document.querySelectorAll(".countdown_wrapper");

    if(countdowns.length > 0)
    {

      console.log("Length");
      console.log(countdowns.length);

      setInterval(() => {
        const now = new Date().getTime();
        const distance = countdownDate - now;

        const days = String(Math.floor(distance / (1000 * 60 * 60 * 24))).padStart(2, '0');
        const hours = String(Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))).padStart(2, '0');
        const minutes = String(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))).padStart(2, '0');
        const seconds = String(Math.floor((distance % (1000 * 60)) / 1000)).padStart(2, '0');

        countdowns.forEach((countdown, index) => {
          countdown.querySelectorAll('.countdown_group').forEach((group, index) => {
            group.querySelectorAll('.countdown_number').forEach((number, i) => {
              number.textContent = [days, hours, minutes, seconds][index][i];
            });
          });
        });

        if (distance < 0) {
          clearInterval(this);
          document.querySelector('.countdown_wrapper').innerHTML = "EXPIRED";
        }
      }, 1000);
    }

   
    


});








